<template>
  <div class="row">
    <div class="col-md-6">
      <card class="stacked-form" title="User Details">
        <h4 slot="header" class="card-title">User Details</h4>
        <form @submit.prevent>
          <div>
            <base-input
              label="Email address"
              type="email"
              placeholder="Enter email"
            >
            </base-input>
            <base-input label="Password" type="password" placeholder="Password">
            </base-input>
            <div class="form-group">
              <base-checkbox>Subscribe to newsletter</base-checkbox>
            </div>
            <base-button class="mt-3" native-type="submit" type="primary"
              >Submit</base-button
            >
          </div>
        </form>
      </card>
    </div>
  </div>
</template>
<script>
import { BaseCheckbox, BaseRadio } from 'src/components/index';

export default {
  components: {
    BaseCheckbox,
  },
  data() {
    return {
      
    };
  }
};
</script>
<style></style>
