<template>
  <div class="row">
    <div class="col-md-6">
      <card class="stacked-form" title="Stacked Form">
        <h3 slot="header" class="title"> {{ $t('account.accountDetails') }}</h3>
        <form @submit.prevent>
          <div>
            <base-input
              :label="$t('account.accountName')"
              type="text"
              v-model="name"
              :placeholder="this.$store.state.account.name"
            >
            </base-input>
            <base-button class="mt-3" native-type="submit" type="primary" @click="renameAccount"
            > {{ $t('account.rename') }}</base-button
            >
          </div>
        </form>
      </card>
    </div>
  </div>
</template>
<script>
import { BaseCheckbox, BaseRadio } from 'src/components/index';

export default {
  components: {
  },
  data() {
    return {
      name: this.$store.state.account.name
    };
  },
  methods: {
    renameAccount() {
      let accessToken = localStorage.getItem("userToken");
      if (this.name != this.$store.state.account.name) {
        const fetchOptions = {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: this.name,
          tokens_q: this.$store.state.account.tokens_q,
          tokens_e: this.$store.state.account.tokens_e
        }),
      };
      console.log(fetchOptions.body);
      const apiUrl = this.$apiEndpoint + `/api/account/`;
      fetch(apiUrl, fetchOptions)
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else if (response.status === 403) {
            throw new Error("Accès non autorisé. Veuillez-vous connecter.");
          } else {
            throw new Error("Unauthorized resource");
          }
        })
        .then((data) => {
          console.log(data);
        });
        console.log(this.name);
      }
    }
  }
};
</script>
<style></style>
