<template>
  <div class="content">
    <div class="row">
      <div class="col-md-6">
        <card class="stacked-form" title="User Details">
          <h4 slot="header" class="card-title"> {{ $t('members.add') }}
            <b>{{ this.$store.getters.getCurrentOrganisation.name }}</b></h4>
          <p> {{ $t('members.addExplaination') }}</p>
          <br>
          <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(submit)" autocomplete="off">
              <div>
                <ValidationProvider name="email" rules="required|email" v-slot="{ passed, failed, errors }">
                  <base-input
                    required
                    v-model="new_user"
                    placeholder="Email"
                    addon-left-icon="tim-icons icon-email-85"
                    :error="errors[0]"
                    :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                    type="email"
                  ></base-input>
                </ValidationProvider>
                <base-button native-type="submit" class="mt-3" type="primary">
                {{ $t('members.btnInvite') }}
                </base-button>
              </div>
            </form>
          </ValidationObserver>
        </card>
      </div>
    </div>
    <div class="col-md-12">
      <card class="card-plain" body-classes="table-full-width">
        <h2> {{ $t('members.list') }}</h2>
        <el-table
          header-cell-class-name="table-transparent"
          header-row-class-name="table-transparent"
          row-class-name="table-transparent"
          :data="tableData"
          id="mainarr"
        >
          <el-table-column
            min-width="200"
            sortable
            :label="$t('members.email')"
            property="email"
          ></el-table-column>
          <el-table-column
            min-width="150"
            sortable
            :label="$t('members.role')"
            property="role"
          ></el-table-column>
          <el-table-column
            min-width="100"
            sortable
            :label="$t('members.isInvitationAccepted')"
            property="is_invitation_accepted"
          ></el-table-column>
          <el-table-column
            min-width="150"
            sortable
            :label="$t('members.invitedDate')"
            property="invited"
          ></el-table-column>
          <el-table-column :min-width="135" align="right" :label="$t('members.actions')">
            <div slot-scope="props">
              <base-button
                @click.native="handleDelete(props.$index, props.row)"
                class="remove btn-link"
                type="danger"
                size="sm"
                icon
              >
                <i class="tim-icons icon-simple-remove"></i>
              </base-button>
            </div>
          </el-table-column>
        </el-table>
      </card>
    </div>
  </div
  >
</template>
<script>
import {Table, TableColumn} from 'element-ui';
import {refreshContext} from "../../utils";
import swal from "sweetalert2";
import { formatDate } from '../../utils';
import {extend} from 'vee-validate';
import {required, email, min} from 'vee-validate/dist/rules';
import { mapMutations } from 'vuex';

extend('email', email);
extend('min', min);
extend('required', required);

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  mounted() {
    this.$store.subscribe((mutation, state) => {
      switch(mutation.type) {
        case 'switchOrganisation':
          this.getUsers();
        break;
      }
    })
    this.getUsers();
  },
  data() {
    return {
      tableData: [],
      new_user: null,
      errors: []
    };
  },
  methods: {
    ...mapMutations([
      'switchOrganisation', // map `this.increment()` to `this.$store.commit('increment')`
    ]),
    getUsers() {
      let accessToken = localStorage.getItem("userToken");

      fetch(this.$apiEndpoint + "/api/orgs/" + this.$store.getters.getCurrentOrganisation.id + "/users", {
        method: "GET",
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        }
      }).then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return new Error("Cannot fetch context");
        }
      }).then((data) => {
        let returnTable = [];
        data.forEach(d => {
          returnTable.push({
            "id": d.id,
            "username": d.username,
            "email": d.email,
            // "email": "damien.lescos@sitincloud.com",
            "invited": formatDate(d.invited),
            "enabled": (d.is_enabled ? <i class="tim-icons icon-check-2"></i> : <i class="tim-icons icon-simple-remove"></i>),
            "is_invitation_accepted": (d.is_invitation_accepted ? <i class="tim-icons icon-check-2"></i> : <i class="tim-icons icon-simple-remove"></i>),
            "role": d.role,
          });
        });
        this.tableData = returnTable;
      });
    },
    submit() {
      this.inviteUser();
    },
    inviteUser() {
      let accessToken = localStorage.getItem("userToken");

      fetch(this.$apiEndpoint + "/api/orgs/" + this.$store.getters.getCurrentOrganisation.id + "/users/invite/" + this.new_user, {
        method: "POST",
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        }
      }).then((response) => {
        console.log(response);
        if (response.ok) {
          refreshContext(this.$store);
          swal.fire({
            icon: 'success',
            title: 'Success',
            text: this.$t('members.memberInvited'),
          });
          return response.json();
        } else {
          response.json().then((errorResponse) => {
            swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: this.$t("errors.backend."+errorResponse.detail),
            });
          });
          throw new Error(errorResponse.detail);
        }
      }).then((data) => {
        this.tableData.push({
          "id": data["user"].id,
          "username": data["user"].username,
          "email": data["user"].email,
          // "email": "damien.lescos@sitincloud.com",
          // "enabled": data["user"].is_enabled,
          "invited": formatDate(data["user"].invited),
          "enabled": (data["user"].is_enabled ? <i class="tim-icons icon-check-2"></i> : <i class="tim-icons icon-simple-remove"></i>),
          "is_invitation_accepted": (data["user"].is_invitation_accepted ? <i class="tim-icons icon-check-2"></i> : <i class="tim-icons icon-simple-remove"></i>),
          "role": data["user"].role,
        });
      })
      .catch((error) => {
        console.error(error);
      });
    },
    handleDelete(index, row) {
      swal.fire({
        title: 'Are you sure?',
        text: `You won't be able to revert this!`,
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill'
        },
        confirmButtonText: 'Yes, delete it!',
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          // this.deleteRow(row);
          this.removeUser(index, row);
        }
      });
    },
    removeUser(index, row) {
      let accessToken = localStorage.getItem("userToken");
      const fetchOptions = {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };
      const apiUrl = this.$apiEndpoint + `/api/orgs/` + this.$store.getters.getCurrentOrganisation.id + `/users/` + row.id;
      fetch(apiUrl, fetchOptions)
        .then((response) => {
          if (response.ok) {
            this.tableData.splice(index, 1);
            swal.fire({
              title: 'Deleted!',
              text: `You deleted ${row.email}`,
              icon: 'success',
              confirmButtonClass: 'btn btn-success btn-fill',
              buttonsStyling: false
            });
            return response.json();
          } else if (response.status === 401) {
            this.logout();
            throw new Error("Accès non autorisé. Veuillez-vous connecter.");
          } else {
            response.json().then((errorResponse) => {
              swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: this.$t("errors.backend."+errorResponse.detail),
              });
            });
            throw new Error(errorResponse.detail);
          }
        })
        .catch((error) => {
          console.error("Error removing user:", error);
        });
      // if (row.id == this.$store.state.user.id) {
      //   this.$store.switchOrganisation(this.$store.state);
      // }
    },
    logout() {
      localStorage.removeItem('userToken'); // Adjust based on your storage method
      localStorage.removeItem('startDate');
      localStorage.removeItem('endDate');
      localStorage.removeItem('exclusions');
      this.$store.dispatch('logout'); // If using Vuex

      this.$router.push("/login");
    }
  }
};
</script>
<style>
.table-transparent {
  background-color: transparent !important;
}
</style>
