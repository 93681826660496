<template>
  <div class="content">
    <!-- <div class="row">
      <div class="col-md-6">
        <card>
          <div>
            <h3 slot="header" class="title"> {{ $t('orgs.tokens') }}</h3>
            <div class="row">
              <div class="col-md-12">
                <base-input
                  type="text"
                  :label="$t('orgs.queriesAvailable')"
                  :disabled="true"
                  v-model="this.$store.state.account.tokens_q"
                >
                </base-input>
              </div>
            </div>
          </div>
          <div class="mt-3">
            <div>
              <div>
                <base-button type="primary" class="btn-fill">
                  {{ $t('orgs.btnOrder') }}
                </base-button>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div> -->

    <div class="row">
      <div class="col-md-12">
        <card class="card-plain" body-classes="table-full-width">
          <h2> {{ $t('orgs.orgsAndTokens') }}</h2>
          <el-table
            header-cell-class-name="table-transparent"
            header-row-class-name="table-transparent"
            row-class-name="table-transparent"
            :data="sortedOrganisations"

            id="mainarr"
          >
            <el-table-column
              min-width="150"
              sortable
              :label="$t('orgs.org')"
              property="name"
            ></el-table-column>
            <el-table-column min-width="150" :label="$t('orgs.queriesAvailable')" prop="tokens_q">
              <template slot-scope="scope">
                <div
                  class="table-actions"

                >
                  <!-- {{ scope.row.tokens_q }} -->
                  <el-tooltip
                    content="Edit"
                    effect="light"
                    :open-delay="300"
                    placement="top"
                  >
                    <base-button
                      @click.native="handleRowEdit('tokens_q', scope.row.$index, scope.row)"
                      type="warning"
                      icon
                      size="sm"
                      class="btn-link"
                    >

                      <i class="tim-icons icon-pencil"></i>
                    </base-button>
                  </el-tooltip>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import {Table, TableColumn} from 'element-ui';
import swal from 'sweetalert2';
import {refreshContext} from "../../utils";


export default {
  components: {

    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  data() {
    return {};
  },
  computed: {
    sortedOrganisations() {
      // Assuming this.$store.state.organisations is an array of objects with an id property
      return [...this.$store.state.organisations].sort((a, b) => a.id - b.id);
    },
  },
  methods: {
    handleRowDelete(index, row) {
      console.log(row);
      let accessToken = localStorage.getItem("userToken");

      fetch(this.$apiEndpoint + "/api/orgs/" + row.id, {
        method: "DELETE",
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json'

        }
      }).then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return new Error("Error fetching endpoint");
        }
      }).then((data) => {
        refreshContext(this.$store);
      });
    },
    handleRowEdit(type, index, row) {
      // Save original value to revert in case of error
      const originalValue = row[type];
      console.log(row);
      console.log(originalValue);

      swal.fire({
        title: this.$t('orgs.tokens4Org'),
        input: 'number',
        inputValue: row[type] || 0, // Show current value as initial input
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill'
        },
        buttonsStyling: false
      })
        .then((result) => {
          if (!result.value) {
            throw new Error('No result value'); // To handle cancel or close without value.
          }
          // Update the property based on the type argument
          row[type] = result.value;
          let accessToken = localStorage.getItem("userToken");
          return fetch(this.$apiEndpoint + "/api/orgs/", {
            method: "PUT",
            headers: {
              'Authorization': `Bearer ${accessToken}`,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(row)
          });
        })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`Server responded with status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          refreshContext(this.$store);
          return swal.fire({
            icon: 'success',
            html: 'Update successful!',
            customClass: {
              confirmButton: 'btn btn-success btn-fill'
            },
            buttonsStyling: false
          });
        })
        .catch((error) => {
          // Revert to original value in case of error
          row[type] = originalValue;
          console.error(error);
          swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong! Your changes were not saved.',
            footer: '<a href="#">Why did I have this issue?</a>'
          });
        });
    }

    ,
    tableRowClassName({rowIndex}) {
      if (rowIndex === 0) {
        return 'table-success';
      } else if (rowIndex === 2) {
        return 'table-info';
      } else if (rowIndex === 4) {
        return 'table-danger';
      } else if (rowIndex === 6) {
        return 'table-warning';
      }
      return '';
    }
  }
};
</script>
<style>
input[disabled="disabled"] {
  color: white !important;
}

.table-transparent {
  background-color: transparent !important;
}
</style>
