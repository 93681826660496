<template>
  <div>
    <h3 class="info-text">Email Validation</h3>
    <div class="row justify-content-center">
      <div class="col-lg-10 text-center">
        <p>Please click on the link in the email that was sent to damien.lescos@sitincloud.com</p>
      </div>
    </div>
  </div>
</template>
<script>
// import { ImageUpload } from 'src/components';

export default {
  methods: {
    validate() {
      this.$emit('on-validated', true, this.model);
      return Promise.resolve(true);
    }
  }
};
</script>
<style></style>
